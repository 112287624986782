<template>
  <div v-if="!$isMobile" class="home">
    <div @click="showSurvey = !showSurvey" class="survey-button">
      <img src="../assets/img/survey.png" alt="">
      <span>满意度调研</span>
    </div>
    <div v-if="showSurvey" class="survey-modal">
      <div class="sm-header">
        <CloseOutlined style="opacity: 0;" />
        <span>满意度调研</span>
        <CloseOutlined @click="showSurvey = false" style="color: #999999; font-size: 14px;" />
      </div>
      <p>1、您对留服CRM管理端使用体验是否满意？<span style="color: #999999;">（匿名）</span></p>
      <div class="sm-scores">
        <span v-for="item in 10" @click="selectScore(item)"
          :class="[currScore == item ? 'score-selected' : 'score-normal']">{{ item }}</span>
      </div>
      <div class="sm-text">
        <span>非常不满意</span>
        <span>非常满意</span>
      </div>
      <p style="margin-bottom: 12px;">2、您还有其他方面的建议吗？<span style="color: #999999;">（选填）</span></p>
      <a-textarea v-model:value="proposal" :maxlength="200" showCount placeholder="请输入..." :rows="4" />
      <div style="text-align: right; margin-top: 40px;">
        <a-button @click="submitSurvey" type="primary">提交</a-button>
      </div>
    </div>
    <div class="flex-col">
      <div class="flex-between" style="height: 120px; margin-bottom: 20px;">
        <a-card :class="[(institutionsName != 1) ? 'show-width' : 'show-width-disabled']"
          style="height: 100%; background-color: #E8EDFF; position: relative;">
          <div class="flex-between-center">
            <div class="flex">
              <a-avatar style="background: #1F94F7; flex-shrink: 0;">
                <template #icon>
                  <UserOutlined />
                </template>
              </a-avatar>
              <div style="margin-left: 10px;position: relative; z-index: 2;">
                <p style="font-weight: bold;">{{ getCurrTime() }}，{{ username }}</p>
                <p v-if="mList.length == 0">欢迎使用留服搜索CRM管理系统</p>
                <div style="overflow-y: scroll; height: 54px; margin-top: 6px; max-width: 600px; padding-right: 20px;"
                  v-else>
                  <p v-for="(item, index) in mList" :key="index">{{ item }}</p>
                </div>
              </div>
            </div>
            <!-- <a href="https://kxyb4lhe59.feishu.cn/docx/IVuedeLMUo5clyxQ3fMcGyAVntb" target="_blank"
              class="home-button">新手教程使用</a> -->
          </div>
          <img class="hh-bg" src="../assets/img/hh-bg.png" alt="">
        </a-card>
        <a-card v-if="institutionsName != 1" style="width: 49%; height: 100%;">
          <p style="color: #121432; font-weight: bold; font-size: 15px;">客户添加/查重</p>
          <div class="input-box">
            <input type="text" class="home-input" v-model="wechatOrPhoneNumberContains" placeholder="输入微信号/手机号添加/查重">
            <span class="hi-button" @click="verifyDuplicate">添加/查重</span>
          </div>
        </a-card>
      </div>
      <div class="flex-between" style="height: 210px; margin-bottom: 20px;">
        <a-card class="ht-card"
          style="width: 24%; height: 100%; background-color: #6672FB; position: relative; overflow: hidden;">
          <a-date-picker style="width: 100px;" v-model:value="moneyDate" picker="month" placeholder="请选择月份"
            @change="getMoney" />
          <div @click="toLink('/fee/settlement')" style="padding-top: 30px; color: #ffffff; cursor: pointer;">
            <p style="text-align: center;"><span style="font-size: 20px; font-weight: bold;">￥{{
    $formatCurrencyNumber(money) }}</span>元</p>
            <p style="text-align: center;">提成金额（元）</p>
          </div>
          <div class="ht-range1"></div>
          <div class="ht-range2"></div>
        </a-card>
        <a-card style="width: 74%; height: 100%;">
          <p class="flex-center">
            <img class="agent-icon" src="../assets/img/agent-icon.png" alt="">
            <span style="color: #121432; font-weight: bold; font-size: 15px;">我的待办</span>
          </p>
          <div class="flex-between-center">
            <div class="agent-box1" @click="toLink('/customer/allocationCustomer')">
              <div class="flex-between-center" style="margin-bottom: 12px;">
                <img class="ab-icon" src="../assets/img/ab-icon1.png" alt="">
                <RightOutlined />
              </div>
              <p>未分配客资（条）</p>
              <p><span style="font-size: 18px; font-weight: bold;">{{ agencyDetail.unassigned ||
    '-'
                  }}</span>条</p>
            </div>
            <div class="agent-box2" @click="toLink('/customer/allocationCustomer?select=toBeFollowedUp')">
              <div class="flex-between-center" style="margin-bottom: 12px;">
                <img class="ab-icon" src="../assets/img/ab-icon2.png" alt="">
                <RightOutlined />
              </div>
              <p>待跟进客资（条）</p>
              <p><span style="font-size: 18px; font-weight: bold;">{{
    agencyDetail.toBeFollowedUp || '-' }}</span>条</p>
            </div>
            <div class="agent-box3" @click="toLink('/order/order')">
              <div class="flex-between-center" style="margin-bottom: 12px;">
                <img class="ab-icon" src="../assets/img/ab-icon3.png" alt="">
                <RightOutlined />
              </div>
              <p>未收款项（元）</p>
              <p><span style="font-size: 18px; font-weight: bold;">￥{{
    $formatCurrencyNumber(agencyDetail.notReceived) || '-'
  }}</span>元</p>
            </div>
          </div>
        </a-card>
      </div>
      <a-card style="width: 100%; height: 500px;">
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <div style="margin-bottom: 20px;">
            <span style="margin-right: 20px; color:#121432; font-weight: bold; font-size: 15px;">精准客资</span>
            <a-range-picker class="page-btm" style="width: 230px; background: #F3F4F8;" v-model:value="businessDate"
              :placeholder="['开始时间', '结束时间']" @change="getBusiness" />
          </div>
          <a-button type="link" @click="toLink('/bom/bomlist')">查看全部</a-button>
        </div>

        <a-table :dataSource="dataSource" :columns="columns" :pagination="tablePagination" @change="changePage">
          <template #bodyCell="{ column, record, text }">
            <template v-if="column.title === '客户姓名'">
              <span>{{ record.institutionsCustomerRelationship.name }}</span>
            </template>
            <template v-if="column.title === '意向等级'">
              <span>{{ getLevel(record.institutionsCustomerRelationship.intentionLevel) }}</span>
            </template>
            <template v-if="column.title === '预计签单项目'">
              <span>{{ record.signingItem.content }}</span>
            </template>
            <template v-if="column.title === '预计签单金额(元)'">
              <span>{{ record.estimatedSigningAmount }}</span>
            </template>
            <template v-if="column.title === '客户阶段'">
              <span>{{ getCustomerPhase(record.customerPhase) }}</span>
            </template>
            <template v-if="column.title === '归属人'">
              <span>{{ record.attribution.username }}</span>
            </template>
            <template v-if="column.title === '跟进人'">
              <span>{{ record.followUpWithPeople.username }}</span>
            </template>
          </template>
        </a-table>
      </a-card>
    </div>
    <a-card class="home-trends">
      <p class="flex-center" style="margin-bottom: 10px;">
        <img class="agent-icon" src="../assets/img/ht-icon.png" alt="">
        <span style="color: #121432; font-weight: bold; font-size: 15px;">客户动向</span>
      </p>
      <div v-if="trendList.length > 0" @scroll="loadData" class="ht-list">
        <div class="hl-item" v-for="(item, index) in trendList">
          <p>{{ item.trend }}</p>
          <p style="color: #999999; margin-top: 6px;">{{ item.date }}</p>
        </div>
        <div class="ht-loading">
          <LoadingOutlined v-if="trendList.length < trendTotal" />
          <span v-else class="ht-load-text">到底了~</span>
        </div>
      </div>
      <div v-else style="text-align: center; margin-top: 100px;">暂无数据</div>
    </a-card>
    <a-modal v-model:open="openModal" title="提示">
      <p style="padding: 20px 0;" v-show="type === 1">
        <ExclamationCircleOutlined style="color: #faad14; margin-right: 6px;" />该客资未重复，是否录入
      </p>
      <p style="padding: 20px 0;" v-show="type === 2 && detail.status != 'LOSS'">客户与<span style="color: #ff4d4f">{{
    detail.creator }}</span>添加的<span style="color: #ff4d4f">{{ detail.name }}</span>重复！当前客户归属人<span
          style="color: #ff4d4f">{{ detail.attribution
          }}</span>，跟进人<span style="color: #ff4d4f">{{ detail.followUpWithPeople }}</span>。客户状态<span
          style="color: #ff4d4f">{{
    getStatus(detail.customerStatus) }}</span>，录入时间：<span style="color: #ff4d4f">{{ getDate(detail.createdDate)
          }}</span>
      </p>
      <p style="padding: 20px 0;" v-show="type === 2 && detail.status == 'LOSS'">客户与<span style="color: #ff4d4f">{{
    detail.creator }}</span>添加的<span style="color: #ff4d4f">{{ detail.name }}</span>重复！当前客户归属人<span
          style="color: #ff4d4f">{{ detail.attribution
          }}</span>，客户状态<span style="color: #ff4d4f">{{
    getStatus(detail.customerStatus) }}</span>，录入时间：<span style="color: #ff4d4f">{{ getDate(detail.createdDate)
          }}</span>
        <span>。当前客户已流入公海，要捞起吗？</span>
      </p>
      <template v-if="type === 1" #footer>
        <a-button @click="close">取消</a-button>
        <a-button type="primary" @click="handleOk">录入</a-button>
      </template>
      <template v-if="type === 2 && detail.status != 'LOSS'" #footer>
        <a-button type="primary" @click="close">确定</a-button>
      </template>
      <template v-if="type === 2 && detail.status == 'LOSS'" #footer>
        <a-button type="primary" @click="PickUp">捞起客资</a-button>
      </template>
    </a-modal>
  </div>
  <div v-else class="m-home">
    <div class="flex-col">
      <a-card style="width: 100%; background-color: #E8EDFF; position: relative;">
        <div class="flex-between-center">
          <div class="flex">
            <a-avatar style="background: #1F94F7; flex-shrink: 0">
              <template #icon>
                <UserOutlined />
              </template>
            </a-avatar>
            <div style="margin-left: 10px; position: relative; z-index: 2;">
              <p style="font-weight: bold;">{{ getCurrTime() }}，{{ username }}</p>
              <p v-if="mList.length == 0" style =" font-size: 12px;">
                欢迎使用留服搜索CRM管理系统
              </p>
              <div style="overflow-y: scroll; height: 76px; margin-top: 6px; max-width: 240px; padding-right: 20px;"
                v-else>
                <p v-for="( item, index ) in  mList " :key=" index ">{{ item }}</p>
              </div>
            </div>
          </div>
          <!-- <a href="https://kxyb4lhe59.feishu.cn/docx/IVuedeLMUo5clyxQ3fMcGyAVntb" target="_blank"
            class="home-button">新手教程使用</a> -->
        </div>
        <img class="hh-bg" src="../assets/img/hh-bg.png" alt="">
      </a-card>
      <a-card v-if=" institutionsName != 1" style="width: 100%; margin-top: 10px;">
        <p>客户添加/查重</p>
        <div class="input-box">
          <input type="text" class="home-input" v-model=" wechatOrPhoneNumberContains " placeholder="输入微信号/手机号添加/查重">
          <span class="hi-button" @click=" verifyDuplicate ">添加/查重</span>
        </div>
      </a-card>
      <a-card
        style="width: 100%; height: 180px; margin-top: 10px; background-color: #6672FB; color:#ffffff;position: relative; overflow: hidden;">
        <a-date-picker v-model:value=" moneyDate " picker="month" placeholder="请选择月份" @change=" getMoney " />
        <div @click="toLink('/fee/settlement')" style="padding-top: 30px;">
          <p style="text-align: center;"><span style="font-size: 20px; font-weight: bold;">￥{{
              $formatCurrencyNumber(money) }}</span>元</p>
          <p style="text-align: center;">提成金额（元）</p>
        </div>
        <div class="ht-range1"></div>
        <div class="ht-range2"></div>
      </a-card>
      <a-card style="width: 100%; margin-top: 10px;">
        <p class="flex-center">
          <img style="width: 24px; margin-right: 6px;" src="../assets/img/agent-icon.png" alt="">
          <span style="color: #121432; font-weight: bold; font-size: 13px;">我的待办</span>
        </p>
        <div class="agent-box1" @click="toLink('/customer/allocationCustomer')">
          <div class="flex-between-center" style="margin-bottom: 20px;">
            <img class="ab-icon" src="../assets/img/ab-icon1.png" alt="">
            <RightOutlined />
          </div>
          <p>未分配客资（条）</p>
          <p><span style="font-size: 20px; font-weight: bold;">{{ agencyDetail.unassigned ||
              '-'
              }}</span>条</p>
        </div>
        <div class="agent-box2" @click="toLink('/customer/allocationCustomer?select=toBeFollowedUp')">
          <div class="flex-between-center" style="margin-bottom: 20px;">
            <img class="ab-icon" src="../assets/img/ab-icon2.png" alt="">
            <RightOutlined />
          </div>
          <p>待跟进客资（条）</p>
          <p><span style="font-size: 20px; font-weight: bold;">{{
              agencyDetail.toBeFollowedUp || '-' }}</span>条</p>
        </div>
        <div class="agent-box3" @click="toLink('/order/order')">
          <div class="flex-between-center" style="margin-bottom: 20px;">
            <img class="ab-icon" src="../assets/img/ab-icon3.png" alt="">
            <RightOutlined />
          </div>
          <p>未收款项（元）</p>
          <p><span style="font-size: 20px; font-weight: bold;">￥{{
              $formatCurrencyNumber(agencyDetail.notReceived) || '-'
              }}</span>元</p>
        </div>
      </a-card>
      <a-card style="width: 100%; margin-top: 10px;">
        <div style="margin-bottom: 20px;">
          <div style="padding-bottom: 10px; display: flex; align-items: center; justify-content: space-between;">
            <span style="color: #121432; font-weight: bold; font-size: 14px;">精准客资</span>
            <span style="color: #1677ff" @click="toLink('/bom/bomlist')">查看全部</span>
          </div>
          <a-range-picker class="page-btm" style="width: 230px; background: #F3F4F8;" v-model:value=" businessDate "
            :placeholder=" ['开始时间', '结束时间'] " @change=" getBusiness " />
        </div>
        <a-table :scroll=" { x: 800 } " :dataSource=" dataSource " :columns=" columns " :pagination=" tablePagination "
          @change=" changePage ">
          <template #bodyCell="{ column, record, text }">
            <template v-if=" column.title === '客户姓名' ">
              <span>{{ record.institutionsCustomerRelationship.name }}</span>
            </template>
            <template v-if=" column.title === '意向等级' ">
              <span>{{ getLevel(record.institutionsCustomerRelationship.intentionLevel) }}</span>
            </template>
            <template v-if=" column.title === '预计签单项目' ">
              <span>{{ record.signingItem.content }}</span>
            </template>
            <template v-if=" column.title === '预计签单金额(元)' ">
              <span>{{ record.estimatedSigningAmount }}</span>
            </template>
            <template v-if=" column.title === '客户阶段' ">
              <span>{{ getCustomerPhase(record.customerPhase) }}</span>
            </template>
            <template v-if=" column.title === '归属人' ">
              <span>{{ record.attribution.username }}</span>
            </template>
            <template v-if=" column.title === '跟进人' ">
              <span>{{ record.followUpWithPeople.username }}</span>
            </template>
          </template>
        </a-table>
      </a-card>
      <a-card class="home-trends">
        <p class="flex-center" style="margin-bottom: 10px;">
          <img style="width: 24px; margin-right: 6px;" src="../assets/img/ht-icon.png" alt="">
          <span style="color: #121432; font-weight: bold; font-size: 13px;">客户动向</span>
        </p>
        <div v-if=" trendList.length > 0 " @scroll=" loadData " class="ht-list">
          <div class="hl-item" v-for="( item, index ) in  trendList ">
            <p>{{ item.trend }}</p>
            <p style="color: #999999; margin-top: 6px;">{{ item.date }}</p>
          </div>
          <div class="ht-loading">
            <LoadingOutlined v-if=" trendList.length < trendTotal " />
            <span v-else class="ht-load-text">到底了~</span>
          </div>
        </div>
        <div v-else style="text-align: center; margin-top: 100px;">暂无数据</div>
      </a-card>
    </div>
    <a-modal v-model:open=" openModal " title="提示">
      <p style="padding: 20px 0;" v-show=" type === 1 ">
        <ExclamationCircleOutlined style="color: #faad14; margin-right: 6px;" />该客资未重复，是否录入
      </p>
      <p style="padding: 20px 0;" v-show=" type === 2 && detail.status != 'LOSS' ">客户与<span style="color: #ff4d4f">{{
          detail.creator
          }}</span>添加的<span style="color: #ff4d4f">{{ detail.name }}</span>重复！当前客户归属人<span style="color: #ff4d4f">{{
          detail.attribution
          }}</span>，跟进人<span style="color: #ff4d4f">{{ detail.followUpWithPeople }}</span>。客户状态<span
          style="color: #ff4d4f">{{
          getStatus(detail.customerStatus) }}</span>，录入时间：<span style="color: #ff4d4f">{{ getDate(detail.createdDate)
          }}</span>
      </p>
      <p style="padding: 20px 0;" v-show=" type === 2 && detail.status == 'LOSS' ">客户与<span style="color: #ff4d4f">{{
          detail.creator
          }}</span>添加的<span style="color: #ff4d4f">{{ detail.name }}</span>重复！当前客户归属人<span style="color: #ff4d4f">{{
          detail.attribution
          }}</span>。客户状态<span style="color: #ff4d4f">{{
          getStatus(detail.customerStatus) }}</span>，录入时间：<span style="color: #ff4d4f">{{ getDate(detail.createdDate)
          }}</span>
        <span>。当前客户已流入公海，要捞起吗？</span>
      </p>
      <template v-if="type === 1" #footer>
        <a-button @click="close">取消</a-button>
        <a-button type="primary" @click="handleOk">录入</a-button>
      </template>
      <template v-if="type === 2 && detail.status != 'LOSS'" #footer>
        <a-button type="primary" @click="close">确定</a-button>
      </template>
      <template v-if="type === 2 && detail.status == 'LOSS'" #footer>
        <a-button type="primary" @click="close">捞起客资</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { getProfile } from "../utils/session";
import dayjs from 'dayjs';
export default {
  name: "Home",
  data() {
    return {
      openModal: false,
      type: 0,
      detail: {},
      profile: null,
      currTime: '',
      wechatOrPhoneNumberContains: '',
      money: '',
      moneyDate: '',
      agencyDetail: {},
      businessDate: [],
      dataSource: [],

      columns: [
        {
          key: 'institutionsCustomerRelationship.name',
          title: '客户姓名',
        },
        {
          key: 'institutionsCustomerRelationship.intentionLevel',
          title: '意向等级',
        },
        {
          key: 'signingItem.content',
          title: '预计签单项目',
          width: 150,
        },
        {
          key: 'estimatedSigningAmount',
          title: '预计签单金额(元)',
          width: 150,
          type: 'numeric',
          config: {
            format: 'currency'
          }
        },
        {
          key: 'customerPhase',
          title: '客户阶段',
        },
        {
          key: 'attribution.username',
          title: '归属人',
          width: 150,
        },

        {
          key: 'followUpWithPeople.username',
          title: '跟进人',
          width: 150,
        },
      ],
      trendList: [],
      tablePagination: {
        pageSize: 5,
        total: 0,
        current: 1,
      },
      page: 0,
      trendTotal: 0,
      trendParams: {
        page: 1
      },
      mList: [],

      showSurvey: false,
      currScore: 10,
      proposal: ''
    }
  },
  // created() {
  //
  // },

  mounted() {
    this.getProfile();
    this.getDay();
    this.agency();
    this.customerTrends();
  },
  computed: {
    username() {
      if (this.profile == null) {
        return '...';
      }
      return this.profile.username;
    },
    institutionsName() {
      if (this.profile == null) {
        return '...';
      }
      return this.profile.institutions.id;
    },
  },

  methods: {

    getDay() {
      const firstDay = dayjs().format('YYYY-MM' + '-01' + ' 00:00:00');
      const lastDay = dayjs().format('YYYY-MM-DD' + ' 23:59:59');
      let days = [];
      days[0] = dayjs(firstDay);
      days[1] = dayjs(lastDay);
      this.businessDate = days;
      this.moneyDate = dayjs(dayjs().format('YYYY-MM'));
      this.royaltyAmount();
      this.opportunityRecordList();
    },

    //获取部门数据
    async verifyDuplicate() {
      if (!this.wechatOrPhoneNumberContains) {
        this.$message.error('请输入微信号/手机号');
        return
      }
      try {
        let res = await this.$http.get('/home/verifyDuplicate', {
          wechatOrPhoneNumberIs: this.wechatOrPhoneNumberContains
        });
        if (res.code == '200') {
          this.detail = res;
          this.type = 2;
          this.openModal = true;
        } else if (res.code == '201') {
          this.type = 1;
          this.openModal = true;
        }
      } catch ({ message }) {

      }
    },

    getMoney() {
      this.royaltyAmount();
    },

    //获取提成金额
    async royaltyAmount() {
      try {
        let res = await this.$http.get('/home/royaltyAmount', {
          yearMonth: this.moneyDate.format('YYYY-MM')
        });
        if (res.code == '200') {
          this.money = res.royalty
        }
      } catch ({ message }) {

      }
    },

    //获取待办
    async agency() {
      try {
        let res = await this.$http.get('/home/agency');
        if (res.code == '200') {
          this.agencyDetail = res.agency
        }
      } catch ({ message }) {

      }
    },

    getBusiness() {
      this.tablePagination.current = 1;
      this.opportunityRecordList();
    },

    changePage(e) {
      this.tablePagination.current = e.current;
      this.opportunityRecordList();
    },

    //精准客资
    async opportunityRecordList() {
      try {
        let res = await this.$http.get('/home/accurateCustomerHistory', {
          page: this.tablePagination.current,
          size: this.tablePagination.pageSize,
          createdDateOnOrAfter: this.businessDate[0].format('YYYY-MM-DD 00:00:00'),
          createdDateOnOrBefore: this.businessDate[1].format('YYYY-MM-DD 23:59:59'),
          sort: '-preciseTime'
        });
        if (res.code == '200') {
          this.dataSource = res.data;
          this.tablePagination.total = res.rows;
        }
      } catch ({ message }) {

      }
    },

    //客户动向
    async customerTrends() {
      try {
        let res = await this.$http.get('/home/customerTrends', {
          page: this.trendParams.page,
          sort: '-createdDate'
        });
        if (res.code == '200') {
          if (this.trendParams.page == 1) {
            this.trendList = res.data;
          } else {
            this.trendList = this.trendList.concat(res.data)
          }

          this.trendList.map((item) => {
            item.date = dayjs(item.createdDate).format('YYYY-MM-DD HH:mm:ss')
          })
          this.trendTotal = res.rows;
        }
      } catch ({ message }) {

      }
    },

    loadData(e) {
      let el = event.target;
      if (el.scrollTop + el.clientHeight + 1 >= el.scrollHeight) {
        if (this.trendList.length < this.trendTotal) {
          this.trendParams.page++;
          this.customerTrends();
        }
      }
    },

    getStatus(val) {
      switch (val) {
        case 'TO_BE_FOLLOWED_UP':
          return '待跟进';
        case 'IN_FOLLOW_UP':
          return '跟进中';
        case 'ACCURATE_CUSTOMER_FUNDING':
          return '精准客资';
        case 'HAS_BECOME_A_SINGLE':
          return '已成单';
        case 'MULTIPLE_ORDERS':
          return '多次成单';
        default:
          break
      }
    },

    getDate(val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss');
    },

    handleOk() {
      this.$router.push({
        path: '/customer/allocationCustomer',
        query: {
          type: 'add',
          wp: this.wechatOrPhoneNumberContains
        }
      })
    },


    toLink(path) {
      this.$router.push(path);
    },

    close() {
      this.openModal = false;
    },

    async getProfile() {
      try {
        this.profile = await getProfile();
        if(this.profile.institutions.id == 1) {
          this.getSingedNotice();
        }
      } catch ({ message, status }) {
        if (status !== 401) { // 不是未登录错误
          this.$message.error(message);
        }
      }
    },

    async getSingedNotice() {
      try {
        let res = await this.$http.get('auth/getSingedNotice');
        if (res.code == '200') {
          this.mList = res.data;
        }
      } catch ({ message }) {

      }
    },

    getCurrTime() {
      let date = new Date();
      if (date.getHours() >= 6 && date.getHours() < 12) {
        return "上午好"
      } else if (date.getHours() >= 12 && date.getHours() < 18) {
        return "下午好"
      } else if (date.getHours() >= 18 && date.getHours() < 24) {
        return "晚上好"
      } else {
        return "凌晨好"
      }
    },

    getLevel(val) {
      if (val) {
        switch (val) {
          case 'HIGH':
            return '高'
          case 'MEDIUM':
            return '中'
          case 'LOW':
            return '低'
          case 'INVALID':
            return '无效'
          default:
            return '无'
        }
      }
    },
    getCustomerPhase(val) {
      if (val) {
        switch (val) {
          case 'CONTRACT_NEGOTIATION':
            return '合同谈判'
          case 'DEAL':
            return '成交'
          case 'LOSS':
            return '流失'
          default:
            return '无'
        }
      }
    },

    async PickUp() {
      try {
        let res = await this.$http.post('/institutions/customer/callingUpTheCustomer', {
          id: this.detail.id
        });
        if (res.code == '200') {
          this.$message.success('捞起成功');
          this.openModal = false;
        }
      } catch ({ message }) {

      }
    },

    selectScore(val) {
      this.currScore = val;
    },

    async submitSurvey() {
      try {
        let res = await this.$http.post('/satisfaction/survey/save', {
          score: this.currScore,
          remark: this.proposal
        });
        if (res.code == '200') {
          this.$message.success('您已提交成功，感谢反馈！');
          this.showSurvey = false;
          this.currScore = 10;
          this.proposal = '';
        }
      } catch ({ message }) {
      }
    }
  }
}
</script>

<style scoped lang="scss">
.home {
  display: flex;
  justify-content: space-between;
}

.show-width {
  width: 49%;
}

.show-width-disabled {
  width: 100%;
}

.hh-bg {
  height: 110px;
  position: absolute;
  right: 30px;
  top: 5px;
  opacity: 0.3;
}

.flex-col {
  width: 76%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.home-trends {
  width: 23%;
  height: 870px;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex {
  display: flex;
}

.home-button {
  display: inline-block;
  border: 1px solid #1677ff;
  padding: 6px 12px;
  border-radius: 12px;
}

.input-box {
  // width: 100%;
  // border-radius: 20px;
  // background-color: #eeeeee;
  margin-top: 8px;
  display: flex;
  align-items: center;
  // justify-content: space-between;

}

.home-input {
  background: none;
  outline: none;
  border: none;
  width: 60%;
  background-color: #E8EDFF;
  padding: 16px 24px;
  border-radius: 12px;
}

.hi-button {
  background-color: #6672FB;
  padding: 12px 18px;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 12px;
  flex-shrink: 0;
}

.ht-range1 {
  background-color: rgba($color: #FFFFFF, $alpha: 0.06);
  width: 172px;
  height: 172px;
  position: absolute;
  top: -60px;
  right: -60px;
  border-radius: 50%;
}

.ht-range2 {
  background-color: rgba($color: #FFFFFF, $alpha: 0.06);
  width: 100px;
  height: 100px;
  position: absolute;
  left: 30px;
  bottom: -40px;
  border-radius: 50%;
}

.flex-center {
  display: flex;
  align-items: center;
}

.agent-icon {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.agent-box1 {
  background-color: #E8523F;
  border-radius: 8px;
  padding: 10px 12px;
  width: 30%;
  margin-top: 16px;
  cursor: pointer;
  color: #ffffff
}

.agent-box2 {
  @extend .agent-box1;
  background-color: #6672FB;
}

.agent-box3 {
  @extend .agent-box1;
  background-color: #FDBC4B;
}

.ab-icon {
  width: 36px;
}

.ht-list {
  overflow-y: scroll;
  height: 780px;
}

.hl-item {
  padding: 20px 0;
  border-bottom: 1px solid #eeeeee;
}

.ht-loading {
  text-align: center;
  font-size: 12px;
  margin: 10px 0;
  color: #999999;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: none;
  /*可以设置轨道颜色*/
  padding-right: 2px;
}

/*滚动条*/
::-webkit-scrollbar-thumb {
  background: rgba(142, 142, 142, 0.4);
  border-radius: 6px;
}

.m-home {
  display: flex;
  justify-content: space-between;

  .flex-col {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .hh-bg {
    position: absolute;
    right: 10px;
    top: 4px;
    height: 50px;
    z-index: 1;
    opacity: 0.3;
  }

  .home-button {
    display: inline-block;
    border: 1px solid #1677ff;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    flex-shrink: 0;
    margin-left: 8px;
  }

  .input-box {
    // width: 100%;
    // border-radius: 20px;
    // background-color: #eeeeee;
    margin-top: 8px;
    display: flex;
    align-items: center;
    // justify-content: space-between;

  }

  .home-input {
    background: none;
    outline: none;
    border: none;
    width: 170px;
    background-color: #E8EDFF;
    padding: 8px 12px;
    border-radius: 4px;
  }

  .hi-button {
    background-color: #6672FB;
    padding: 4px 12px;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    margin-left: 12px;
  }

  .agent-box1 {
    background-color: #E8523F;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    padding: 10px 16px;
    width: 100%;
    margin-top: 16px;
    cursor: pointer;
  }

  .agent-box2 {
    @extend .agent-box1;
    background-color: #6672FB;
  }

  .agent-box3 {
    @extend .agent-box1;
    background-color: #FDBC4B;
  }

  .home-trends {
    width: 100%;
    height: 600px;
    margin-top: 10px;
  }

  .ht-list {
    overflow-y: scroll;
    height: 530px;
  }

  .ht-range1 {
    background-color: rgba($color: #FFFFFF, $alpha: 0.06);
    width: 100px;
    height: 100px;
    position: absolute;
    top: -20px;
    right: -20px;
    border-radius: 50%;
  }

  .ht-range2 {
    background-color: rgba($color: #FFFFFF, $alpha: 0.06);
    width: 60px;
    height: 60px;
    position: absolute;
    left: 20px;
    bottom: -20px;
    border-radius: 50%;
  }

  .ab-icon {
    width: 30px;
  }

  :deep(.ant-card-body) {
    padding: 10px;
  }

  :deep(.ant-card-head) {
    padding: 6px;
  }
}

.ht-card {
  :deep(.ant-card-body) {
    padding: 12px;
  }
}

:deep(.ant-table-thead > tr >th) {
  background: #E8EDFF !important;
}

::-webkit-scrollbar {
  display: none;
}

.survey-button {
  position: fixed;
  right: 60px;
  bottom: 120px;
  box-shadow: 0px 4px 40px 8px #00000014;
  border-radius: 16px;
  padding: 14px;
  background-color: #ffffff;
  font-size: 14px;
  color: #3385FF;
  z-index: 2;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    margin-bottom: 2px;
  }
}

.survey-modal {
  position: fixed;
  right: 180px;
  bottom: 120px;
  box-shadow: 0px 4px 40px 8px #00000014;
  padding: 24px;
  z-index: 2;
  border-radius: 16px;
  min-width: 420px;
  background-color: #ffffff;

  .sm-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    span {
      font-size: 20px;
    }
  }

  .sm-scores {
    display: flex;
    padding: 12px 0;
  }

  .score-selected {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: #3385FF;
    line-height: 24px;
    text-align: center;
    margin-right: 8px;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px
  }

  .score-normal {
    @extend .score-selected;
    background-color: #F3F7FA;
    color: #333333;
  }
}

.sm-text {
  margin-bottom: 20px;
  color: #666666;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #666666;

  span {
    cursor: pointer;
  }
}
</style>